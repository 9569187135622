<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <h1 class="banner_title b_b_space font-size_60">医药营销管理解决方案</h1>
          <h3 class="banner_title_2 b_b_space font-size_25">
            <div>医药营销管理的数字化变革</div>
          </h3>
          <p class="banner_desc b_b_space font-size_16">
            行业趋势，摆在这里；时代性问题，摆在这里；内生性态势，摆在这里……带量采购(VBP)、国家谈判、医保支付、医药代表备案制、一致性评价等政策性的影响下，医药产业的营销也发生了巨大的变化。从管理要效率，提效率降成本，营销数字化与管理数字化，已经成为中国医药企业的内生性变革的重要环节。
          </p>
          <h3 class="banner_title_2 b_b_space font-size_25">
            <div>洞察与驱动</div>
          </h3>
          <p class="banner_desc_1 b_b_space font-size_16">
            1.如何建立符合行业标准的企业数据体系？<br />
            2.如何将企业数据（主数据、行为数据、分析数据）与核心业务相结合？<br />
            3.如何跨越传统营销管理方式，建立数据化管理体系？<br />
            4.如何对数据进行智能分析，洞察推动业务指标变化的因素，挖掘数据背后的价值？<br />
            5.如何建立支撑多场景营销管理模式的信息系统？<br />
            6.如何建立费用结算体系？
          </p>
        </div>
      </div>
      <h2 class="header_title b_b_space font-size_40">解决方案</h2>
      <div class="l_r_space t__space medicineManagement_1">
        <a-row type="flex" style="align-items: center" :gutter="122">
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <img
              src="@/assets/img/png/medicine-management_1.png"
              alt="正也S2P推动数字化管理转型"
              style="width: 100%; max-width: 720px"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <div class="text_left">
              <h3
                class="nav_thirld——title mouse-style font-size_32"
                @click="toPage('solutionInformatization')"
              >
                正也S2P推动数字化管理转型
                <div class="right-circle"></div>
              </h3>
              <p style="max-width: 492px; color: #666" class="font-size_20">
                通过“管理+咨询+SaaS系统”的联动模式，为客户提供从传统营销管理提升到“智能化管理“，包括企业主数机制优化、业务流程优化及知识管理、信息化建设战略规划、数据分析体系，IT系统建设规化，切实帮助客户实现转型升级与价值落地。
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="l_r_space t__space bgc_f4fbff medicineManagement_2">
        <div class="medicineManagement">
          <a-row type="flex" style="align-items: center">
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <div class="text_left">
                <h3
                  class="nav_thirld——title mouse-style font-size_32"
                  @click="toPage('solutionDirectSelling')"
                >
                  S2P全场景营销管理解决方案
                  <div class="right-circle"></div>
                </h3>
                <div>
                  <p style="max-width: 455px; color: #666" class="font-size_20">
                    S2P适用多渠道全模式：
                  </p>
                  <p style="max-width: 455px; color: #666" class="font-size_20">
                    OTC零售、处方临床、基层第三终端广阔市场、商务渠道、招商代理、市场推广、混合制等多种团队模式。
                  </p>
                  <p style="max-width: 455px; color: #666" class="font-size_20">
                    S2P适用多客户全层级：
                  </p>
                  <p style="max-width: 455px; color: #666" class="font-size_20">
                    以医院、医生、药店、连锁、基层、配送商、服务商为对象；以业务人员拜访活动为主线；行为指标体系为依据；协同协访、业务指导，以数据持续沉淀与应用为核心，建立“数据化管理平台”，赋能营销决策。
                  </p>
                  <p style="max-width: 455px; color: #666" class="font-size_20">
                    S2P适用多类型全过程：
                  </p>
                  <p style="max-width: 455px; color: #666" class="font-size_20">
                    正也S2P管理平台可以为医药营销管理提供——辖区管理、客户关系管理、执行过程管理、销售指标管理、市场活动管理、学术会议管理和多维度多权限的可视化智能报表，解决当前面临的“多产品多渠道多模式”的管理问题。
                  </p>
                </div>
              </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <img
                src="@/assets/img/png/medicine-management_2.png"
                alt="S2P全场景营销管理解决方案"
                style="width: 100%; max-width: 590px"
              />
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="l_r_space t__space medicineManagement_3">
        <a-row type="flex" style="align-items: center" :gutter="200">
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <img
              src="@/assets/img/png/medicine-management_3.png"
              alt="正也S2P费用结算管理解决方案"
              style="width: 100%; max-width: 580px"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <div class="text_left">
              <h3
                class="nav_thirld——title mouse-style font-size_32"
                @click="toPage('solutionChannelOfDistribution')"
              >
                正也S2P费用结算管理解决方案
                <div class="right-circle"></div>
              </h3>
              <p style="max-width: 492px; color: #666" class="font-size_20">
                正也S2P智能管理平台针对招商代理营销模式下的管理痛点，采用协议及合同管理、辖区管理、进销存管理、费用管理、回款管理以及预警管理，对营销过程中的执行状态进行统计分析预警。以“（流向+单据）×算法”的策略，围绕流向、费用、回款、结算，对数据进行智能计算分析。
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="l_r_space t__space bgc_f4fbff medicineManagement_4">
        <div class="medicineManagement">
          <a-row type="flex" style="align-items: center">
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <div class="text_left">
                <h3
                  class="nav_thirld——title mouse-style font-size_32"
                  @click="toPage('solutionBazaarAdministration')"
                >
                  合规证据链管理解决方案
                  <div class="right-circle"></div>
                </h3>
                <p style="max-width: 477px; color: #666" class="font-size_20">
                  正也S2P智能管理平台是面向未来的、基于“代表备案制度”体系下的合规证据链系统，集云端、移动端（微信小程序）一体化的应用型工具。为医药企业、营销服务商提供基础信息管理服务，可用于收集与汇总综合服务商、项目服务商的日常工作数据，为医药营销与服务，提供有效的合规证据链。
                </p>
              </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <img
                src="@/assets/img/png/medicine-management_4.png"
                alt="支付证据管理解决方案"
                style="width: 100%; max-width: 630px"
              />
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
export default {
  name: "medicineManagement",
  beforeCreate() {},
  components: { Footer, HeaderMenu },
  data() {
    return {};
  },
  created() {},
  methods: {
    toPage(name) {
      return;
      this.$router.push({
        name,
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.nav_thirld——title {
  color: $color-lightBlue;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}
.right-circle {
  width: 0.28rem;
  height: 0.28rem;
  display: inline-block;
  margin-left: 0.16rem;
  vertical-align: middle;
}
.header_title {
  padding-top: 0.46rem;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/medicine-management_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1rem;
    left: 8%;
    .banner_title {
      color: $color-lightBlue;
      letter-spacing: 0.1rem;
    }
    .banner_title_2 {
      color: $color-lightBlue;
      margin-top: 0.2rem;
    }
    .banner_desc {
      width: 6.5rem;
      padding-top: 0.2rem;
    }
    .banner_desc_1 {
      width: 6.5rem;
      padding-top: 0.1rem;
    }
  }
}
.medicineManagement_1 {
  padding-bottom: 1rem;
}
.medicineManagement_2 {
  padding-top: 1.11rem;
  padding-bottom: 1.4rem;
}
.medicineManagement_3 {
  padding-top: 1.2rem;
  padding-bottom: 1.4rem;
}
.medicineManagement_4 {
  padding-top: 1.6rem;
  padding-bottom: 1.9rem;
}
@media all and (max-width: 1100px) {
  // .right-circle {
  //   width: 12px;
  //   height: 12px;
  // }
}
</style>
